let playButton = document.querySelector('.thumbnail__play-button');
let closeModalButton = document.querySelector('.modal__close');
let iframe = document.querySelector('.responsive-container iframe');
let video = document.querySelector('.responsive-container video');
let modal = document.querySelector('.hero-cirkel-section__modal');
let videoPlayer = modal ? modal.dataset.videoPlayer : null;

//Open btn
if (playButton) {
    playButton.addEventListener('click', function () {
        if (modal.classList.contains('hero-cirkel-section__modal--active')) {
            closeModal();
        }

        else {
            openModal();
        }
    })
}

if (closeModalButton) {
    //Close btn
    closeModalButton.addEventListener('click', function () {
        closeModal();
    })
}


//Open function
var openModal = function () {
    modal.classList.add('hero-cirkel-section__modal--active');

    if (iframe) {
        document.resetUrl = iframe.src;
        if (iframe.src.indexOf('?autoplay=1') == -1 && iframe.src.indexOf('&autoplay=1') == -1) {
            if (iframe.src.indexOf('?') > -1) {
                iframe.src += "&autoplay=1";
            } else {
                iframe.src += "?autoplay=1";
            }
        }
        else {
            iframe.src = iframe.src;
        }
    }
    else {
        var video = modal.querySelector('video');
        video.play();
    }
}
//Close function
var closeModal = function () {
    modal.classList.remove('hero-cirkel-section__modal--active');

    if (iframe) {
        if (iframe.src.indexOf('?autoplay=1') > -1) {
            iframe.src = iframe.src.replace('?autoplay=1', '');
        }
        else {
            iframe.src = iframe.src.replace('&autoplay=1', '');
        }
    }
    if (video) {
        video.pause();
        video.currentTime = 0;
    }
}